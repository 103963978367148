import React, { useEffect } from 'react';
import { Button, Col, Container, Row, CardDeck, Image } from 'react-bootstrap';
import { Link } from 'gatsby';
import HeaderExpanded from '../components/HeaderExpanded/HeaderExpanded';
import IntegrationCard from '../components/IntegrationCard/IntegrationCard';
import '../styles/spray-plan-manager.scss';
import { Helmet } from 'react-helmet';

function SprayPlanManager() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* Add meta tags inside here. */}
      <Helmet>
        <title>HortPlus | HortPlus SprayPlan Manager</title>
        <meta name='description' content='SprayPlan Manager is a web-based tool to help growers responsibly
              manage agrichemicals' />
      </Helmet>
      <HeaderExpanded title='HortPlus SprayPlan Manager' />
      <Container className='my-5'>
        <Row>
          <Col md={6} className='d-flex flex-column justify-self-center'>
            <p>
              SprayPlan Manager is a web-based tool to help growers responsibly
              manage agrichemicals. It identifies agrichemicals that have
              adverse effects on people and the environment. It also helps
              determine sensitive areas and hazards, outlines responsible
              chemical use, safety measures, and strategies to avoid spray
              drift.
            </p>
            <p>
              SprayPlan Manager produces electronic reports and property spray
              plans that are a legislative requirement in most regions in New
              Zealand through regional council air plans. SprayPlan Manager can
              assist you in creating a Spray Plan for your property but it is
              important to understand a Property Spray Plan is not a Spray
              Diary.
            </p>
            <p>
              The focus of a Property Spray Plan is on identifying and
              mitigating the effects agrichemical applications have on the
              surroundings. Once you've registered, you'll be guided through a
              simple process, helping you to collate information about your
              property, the agrichemicals used, and the effects on the
              surroundings, both environmental and people, into a plan for
              printing or emailing.
            </p>
            <Button className='my-3' as={Link} to={'/contact'}>
              Contact us to find out more
            </Button>
          </Col>
          <Col md={6}>
            <h6 className='hp-font-28'>Features</h6>
            <ul>
              <li>Responsibly manage agrichemicals</li>
              <li>
                Protect yourself, your neighbours, your staff and the
                environment
              </li>
              <li>Avoid hazards and spray drift</li>
              <li>Runs on any computer</li>
              <li>Identifies safe use of agrichemicals</li>
              <li>Complies with GLOBAL G.A.P</li>
              <li>Comply with Regional Council air plans</li>
            </ul>
          </Col>
        </Row>
        <Container className='text-center mt-5'>
          <Image
            src={process.env.GATSBY_S3_BUCKET_ASSETS + 'brands/nzgap_logo.png'}
            alt='NZ GAP'
            width='200'
            className='p-3'
            fluid
          />

          <Image
            src={
              process.env.GATSBY_S3_BUCKET_ASSETS + 'brands/growsafe_logo.png'
            }
            alt='NZ GrowSafe'
            width='200'
            className='p-3'
            fluid
          />
        </Container>
      </Container>
      <Container fluid className='hp-page hp-page--blue-grey'>
        <Container fluid className='d-flex flex-column align-items-center'>
          <CardDeck className='hp-carddeck-width justify-content-center'>
            <IntegrationCard type={'sprayview'} />
            <IntegrationCard type={'traplog'} />
            <IntegrationCard type={'sprayplanmanager'} />
            <IntegrationCard type={'spraylog'} />
          </CardDeck>
        </Container>
      </Container>
    </>
  );
}

export default SprayPlanManager;
